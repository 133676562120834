
import { Component, Vue } from "vue-property-decorator";
// import { Field } from 'vant';
// import { accountLoginRequest } from "@/service/api";
import { request, closeWebView } from "@/service/libs/index";
import { Toast } from "vant";

@Component({
  name: "PerfectSuccess",
  components: {
    // HelloWorld,
    // Field,
  },
})
export default class PerfectSuccess extends Vue {
  private async handleBuy() {
    closeWebView();
  }
  private orderInfo: any = {};
  private orderStatusText: string = this.$t("inmoneycomplete") as string;
  private inAccountDateTime: string = "";
  private timestampToTime(timestamp: any) {
    // 时间戳为10位需*1000，时间戳为13位不需乘1000
    var date = new Date(timestamp * 1000);
    var Y = date.getFullYear() + "-";
    var M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    var h = date.getHours() + ":";
    var m = date.getMinutes() + ":";
    var s = date.getSeconds();
    return Y + M + D + h + m + s;
  }
  // 查询单个支付渠道
  private async getOrderInfo() {
    let order = localStorage.getItem("perfect-orderId");
    console.log(order);
    if (!order) {
      Toast.fail(this.$t("weichaxundaodingdahao"));
      return;
    }
    let result: any = await request({
      method: "GET",
      path: "/v1/api/pay/order/info?orderNo=" + order,
    });
    this.orderInfo = result;
    console.log(this.orderInfo);

    if (this.orderInfo.status == 1) {
      this.orderStatusText = this.$t("zhifuwanctip") as string;
      Toast.success(this.$t("zhifuwanctip"));
      // 系统处理中，请稍后查看账户 ，时间和金额不显示
    } else {
      this.inAccountDateTime = this.timestampToTime(this.orderInfo.payTime);
    }
  }
  mounted() {
    setTimeout(() => {
      this.getOrderInfo();
    }, 100);
  }
}
